
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonSpinner,
  IonBadge,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import { add, chevronForward } from "ionicons/icons";

import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import moment from "moment";

import apiTicket from "../services/ticket";

import { openToast } from "../services/toast";

import { DatePicker } from "v-calendar";

export default {
  name: "FreeTicketDetail",
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonBadge,
    IonButton,
    IonIcon,
    DatePicker,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route.params.id as string; //free ticket_id

    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;

    const loading = ref<boolean>(false);
    const ticket = ref([]);

    const tecnici = ref([]); //auto assegnazione ticket

    const created = ref(false); //flag per disabilitare button crea intervento nel caso in cui utente torni indietro da dettaglio intervento

    const assignDate = ref(new Date()); //selezione data assegnazione ticket
    const showCalendar = ref(false);

    /**
     * Auto assign ticket to the logged user for today
     */
    function autoAssignTicket() {
      //console.log(assignDate.value);
      const todayDate = moment().format("YYYY-MM-DD");
      const autoAssignDate = moment(assignDate.value).format("YYYY-MM-DD");

      if (autoAssignDate < todayDate) {
        openToast(
          "Non puoi prendere in carico il ticket per una data già passata",
          "danger"
        );
        return;
      } else {
        apiTicket
          .assignTicket(id, tecnici.value, autoAssignDate)
          .then((response) => {
            //console.log(response);
            openToast("Ticket assegnato correttamente", "success");
            router.replace("/tabs/tab1");
          })
          .catch((error) => {
            //console.error(error);
            openToast("Errore durante l'assegnazione del ticket", "danger");
          })
          .finally(() => {
            //console.log("fine autoassegnamento ticket");
          });
      }
    }

    /**
     * Print name and last name or customer company based on condition
     */
    /*     watch(assignDate, (date, prevDate) => {
      //console.log(date);
      //console.log(prevDate);
      //close calendar and send data
      showCalendar.value = false;
      autoAssignTicket();
    }); */

    /**
     * Print name and last name or customer company based on condition
     */
    const riferimentoCliente = computed(() => {
      return (ticket) => {
        if (ticket.customers_name && ticket.customers_last_name) {
          return `${ticket.customers_name} ${ticket.customers_last_name}`;
        } else {
          return `${ticket.customers_company}`;
        }
      };
    });

    /**
     * Set correct background for ticket status
     */
    const statoTicket = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 1) {
          //aperto
          className = "danger";
        } else if (statusId == 2) {
          //in lavorazione
          className = "dark";
        } else if (statusId == 3) {
          //preso in carico
          className = "warning";
        } else if (statusId == 5) {
          //chiuso
          className = "success";
        }
        return className;
      };
    });

    /**
     * Return '-' if field is null
     */
    function checkField(field) {
      if (field == null || field == "") {
        return "-";
      } else {
        return field;
      }
    }

    /**
     * Remove html tag from ticket description
     */
    function parseHtmlEnteties(str) {
      const strippedString = str.replace(/(<([^>]+)>)/gi, "");
      return strippedString.replace(
        /&#([0-9]{1,3});/gi,
        function (match, numStr) {
          const num = parseInt(numStr, 10); // read num as normal number
          return String.fromCharCode(num);
        }
      );
    }

    /**
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     */
    function dateFormat(date) {
      if (date && moment.isDate(new Date(date))) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    }

    onMounted(() => {
      //GET TICKET DATA
      loading.value = true;
      apiTicket
        .getFreeTicketDetail(id)
        .then((response) => {
          //console.log(response);
          ticket.value = response;
        })
        .catch((error) => {
          //error.value = true;
          console.error(error);
          openToast("Errore durante la richiesta del ticket", "danger");
        })
        .finally(() => {
          loading.value = false;
          //console.log("fine");
        });

      //Used for auto assegnazione ticket
      tecnici.value.push(userID);
    });

    return {
      loading,
      ticket,
      riferimentoCliente,
      dateFormat,
      checkField,
      parseHtmlEnteties,
      add,
      statoTicket,
      //flag for disabling button
      created,
      //New field after figma layout
      chevronForward,
      autoAssignTicket,
      //select date to autoassign ticket
      assignDate,
      showCalendar,
    };
  },
};
